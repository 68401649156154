import React from 'react'
import SplitSection from './SplitSection';

export const Service = ({ title, children ,picture,reverseOrder}) => {
  return (
    <SplitSection
      {...{reverseOrder}}
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">{title}</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            {children}
          </p>
        </div>
      }
      secondarySlot={picture}
    />
  )
}
