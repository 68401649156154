import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SEO from '../components/layout/seo';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import Banner from '../svg/Banner';
import StyledButton from '../components/StyledButton';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Service } from '../components/Service';
import { graphql, withPrefix } from 'gatsby';
import { Feature } from '../components/Feature';
import { Product } from '../components/Product';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import classNames from 'classnames';

const Index = ({ data }) => {
  const services = data.services.edges;
  const features = data.features.edges;
  const products = data.products.edges;
  const customers = data.customers.edges.concat(data.customers.edges);
  return (
  <Layout>
    <SEO title='Home'/>
    <section className="pt-20 md:pt-40">
      <div className="container px-8 mx-auto lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl font-bold leading-none lg:text-5xl xl:text-6xl">
          <Banner/>
          </h1>
          
          <p className="mt-6 text-xl font-light lg:text-2xl">
          Nous accompagnons nos clients dans leur demarches 
de transformation adaptables aux enjeux de demain, en toute agilité.
	Ensemble, donnons vie aux solutions innoventes qui naissent
                                    dans votre imagination à travers la digitalisation.

          </p>
          
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
      
    
    </section>
    <section className="flex flex-col mt-10 md:mt-16">
      <div className="container mx-auto text-center">
        <h2 className="self-center mb-2 text-4xl font-semibold text-gray-800">Nos atouts</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3">
        {features.map(({ node },index) => (
              <Feature
                title={node.frontmatter.title}
                key={index}
              >
                {node.excerpt}
              </Feature>
            ))}
        </div>
      </div>
    </section>
    <section id='services' style={{backgroundColor: 'rgb(250, 250, 250)'}}>
    {services.map(({ node },index) => (
              <Service
                title={node.frontmatter.title}
                key={node.frontmatter.path}
                reverseOrder={index & 1}
                picture={<img className="max-w-lg h-96" src={withPrefix(node.frontmatter.image)} alt={node.frontmatter.title}/>}
              >
                {node.excerpt}
              </Service>
            ))}
    </section>
    <section id='products' className="flex flex-colmt-10 md:mt-16">
      <div className='container self-center mx-auto text-center'>
          <p className="self-center mb-2 text-4xl text-gray-800">
            Nos produits
          </p>

          <div className="flex flex-wrap items-stretch justify-center">
          {products.map(({ node },index) => (
              <Product
                title={node.frontmatter.title}
                key={index}
              >
                {node.excerpt}
              </Product>
            ))}
          </div>
          </div>
        </section>
    <section id="reference" className="py-20 lg:pt-20" >
      <div className="container flex flex-col self-center mx-auto mt-10 text-center md:mt-16">
      <p className="self-center mb-2 text-4xl text-gray-800">
      Ils nous font confiance
          </p>
        <div 
          className="slider"
          >
            <div className='slide-track'>
        {customers.concat(customers.concat(customers)).concat(customers).concat(customers).map(({ node },index) => (
             <div className="w-64 h-32" key={index}>
              <div className="flex flex-col self-start h-full">
                <a className='m-auto' href={node.frontmatter.url} title={node.frontmatter.title}><img className='flex-none ' style={{maxHeight:'8rem'}} alt={node.frontmatter.title} src={node.frontmatter.image} /></a>
              </div>
              </div>
            ))}
            </div>
        </div>
        </div>
    </section>
  </Layout>
);
}
export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            image
          }
          html
          excerpt
        }
      }
    },
    features: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/features/" } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          excerpt
        }
      }
    },
    products: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/products/" } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          excerpt
        }
      }
    },
    customers: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/customers/" } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            image
            url
          }
          excerpt
        }
      }
    }
  }
`;
export default Index;
