import React from 'react'
import Card from "./Card"

export const Feature = ({ title, children }) => {
  return (
    <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">{title}</p>
              <p className="mt-4 text-justify">
                {children}
              </p>
            </Card>
          </div>
  )
}