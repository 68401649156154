import React from 'react'

export const Product = ({title,children,picture}) => {
  return (
    <div className="w-full md:w-1/2 lg:w-1/3 mt-2">
              <div className="h-full m-2 p-4 border-2 border-gray-300 flex flex-col items-center text-center">
                <p className="text-2xl w-full">{title}</p>
                <p>{children}</p>
              </div>
            </div>
  )
}
